<script>
  import "@vueform/multiselect/themes/default.css";
  import { overlay } from "@/state/helpers";
  import Layout from "@/layouts/main.vue";
  import PageHeader from "@/components/page-header";
  import SettingsSide  from "@/components/settings-side.vue";
  import EmptyTableResult from "@/components/empty-table-result";
  import appConfig from "@/../app.config";
  import Swal from "sweetalert2";
  import { callUrl, getUserData } from '@/helpers/api-apolo';
  import { saError } from '@/helpers/global-functions';
  import * as helpers from '@/helpers/helper-functions';

  export default {
    page: {
      title: "Token Integração",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    data() {
      return {
        title: "Token Integração",
        items: [{
            text: "Configurações",
            href: "/"
          },
          {
            text: "Token Integração",
            active: true
          },
        ],
        page: 1,
        perPage: 10,
        dataList: []
      }
    },
    components: {
      Layout,
      PageHeader,
      SettingsSide,
      EmptyTableResult
    },
    computed: {
      pages() {
        if (this.page == 1 && !this.dataList.length) {
          return [];
        }
        if (this.page == 1 && this.dataList.length < this.perPage) {
          return [1];
        }
        if (this.page == 1 && this.dataList.length == this.perPage) {
          return [1, 2];
        }
        if (this.page > 1 && (!this.dataList.length || this.dataList.length < this.perPage)) {
          return [this.page - 1, this.page];
        }
        return [this.page - 1, this.page, this.page + 1];
      }
    },
    watch: {
      page() {
        this.fetchData();
      }
    },
    methods: {
      ...overlay,
      ...helpers,
      search() {
        if (this.page !== 1) {
          this.page = 1;
        } else {
          this.fetchData();
        }
      },
      async fetchData() {
        try {
          this.changeShowOverlay({show: true});
          this.dataList = [];
          const params  = [`page=${this.page}`, `limit=${this.perPage}`, `order=created_at:1`];
          const dataM   = await callUrl({}, `/integration-token?${params.join('&')}`, 'get');
          if (dataM && dataM.length) {
            this.dataList = dataM;
          }
        } catch (error) {
          console.log(error.message);
        }
        this.changeShowOverlay({show: false});
      },
      async deletedata(event) {
        Swal.fire({
          title: "Você tem certeza que deseja remover este token de integração?",
          text: "Não será possível reverter esta ação!",
          icon: "warning",
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: "Sim, remover!",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
          customClass: {
            actions: 'd-flex justify-content-center',
            confirmButton: 'btn btn-success flex-grow-1',
            cancelButton: 'btn btn-outline-dark flex-grow-1'
          }
        }).then(async (result) => {
          if (result.value) {
            try {
              this.changeShowOverlay({show: true});
              await callUrl({}, '/integration-token/' + event.access_key, 'delete');
              this.changeShowOverlay({show: false});
              Swal.fire("Sucesso!", "Token removido com sucesso", "success");
              this.search();
            } catch (error) {
              this.changeShowOverlay({show: false});
              saError(error.message);
            }
          }
        });
      },
      addNew() {
        Swal.fire({
          title: "Você tem certeza que deseja criar um novo token de integração?",
          text: "",
          icon: "warning",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonText: "Sim, criar!",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
          customClass: {
            actions: 'd-flex justify-content-center',
            confirmButton: 'btn btn-success flex-grow-1',
            cancelButton: 'btn btn-outline-dark flex-grow-1'
          }
        }).then(async (result) => {
          if (result.value) {
            try {
              this.changeShowOverlay({show: true});
              await callUrl({}, '/integration-token', 'post');
              this.changeShowOverlay({show: false});
              Swal.fire("Sucesso!", "Novo token criado com sucesso", "success");
              this.search();
            } catch (error) {
              this.changeShowOverlay({show: false});
              saError(error.message);
            }
          }
        });
      }
    },
    mounted() {
      const userData = getUserData();
      if (userData.profile !== 'master') {
        return this.$router.push({ name: 'default' });
      }
      this.fetchData();
    }
  };
</script>

<style scoped>
@media only screen and (max-width: 500px) {
    .card-header h5 { display: none; }
}
@media only screen and (max-width: 390px) {
    .card-header button i { display: none; }
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-sm-12 col-lg-2 col-md-3">
        <SettingsSide :actualRoute="'tokens'"></SettingsSide>
      </div>
      <div class="col-lg-10 col-md-9 col-sm-12">
        <div class="card" id="customFieldList">

          <div class="card-header border-0">
            <div class="d-flex align-items-center">
              <h5 class="card-title mb-0 flex-grow-1">Tokens de Integração</h5>
              <div class="flex-shrink-0">
                <button class="btn btn-success add-btn ms-3" data-bs-toggle="modal" href="#showmodal" @click="addNew">
                  <i class="ri-add-line align-bottom me-1"></i> Criar novo token
                </button>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div>
              <div class="table-responsive table-card">
                <table class="table table-striped align-middle table-nowrap">
                  <thead class="table-primary">
                    <tr>
                      <th class="sort text-uppercase">Access Key</th>
                      <th class="sort text-uppercase">Access Secret</th>
                      <th class="sort text-uppercase">Criado em</th>
                      <th class="sort text-uppercase">Ações</th>
                    </tr>
                  </thead>
                  <tbody class="list form-check-all">
                    <tr v-for="(item, index) of dataList" :key="index">
                      <td>{{item.access_key}}</td>
                      <td>{{item.access_secret}}</td>
                      <td>{{translateDateOnly(item.created_at)}} <small class="text-muted">{{translateTimeOnly(item.created_at)}}</small></td>
                      <td>
                        <div class="dropdown">
                          <button class="btn btn-soft-secondary btn-sm dropdown" type="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <i class="ri-more-fill align-middle"></i>
                          </button>
                          <ul class="dropdown-menu dropdown-menu-end">
                            <li>
                              <a class="dropdown-item remove-item-btn" @click.prevent="deletedata(item)" style="cursor:pointer">
                                <i class="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Remover
                              </a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <EmptyTableResult v-show="dataList.length == 0" />

              </div>
              <div class="d-flex justify-content-end mt-3">
                <div class="pagination-wrap hstack gap-2">
                  <a class="page-item pagination-prev" href="#" @click="--page" v-if="page > 1">Anterior</a>
                  <a class="page-item pagination-prev disabled" href="#" v-if="dataList.length && page == 1" @click.prevent="">Anterior</a>
                  <ul class="pagination listjs-pagination mb-0">
                    <li v-for="pageNumber in pages" :key="'page' + pageNumber" @click="page = pageNumber"
                      :class="{active: pageNumber == page, disabled: pageNumber != page}">
                      <a class="page" href="#">{{ pageNumber }}</a>
                    </li>
                  </ul>
                  <a class="page-item pagination-next" href="#" @click="++page" v-if="dataList.length == perPage">Próxima</a>
                  <a class="page-item pagination-next disabled" href="#" @click.prevent="" v-if="dataList.length && dataList.length < perPage">Próxima</a>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </Layout>
</template>
